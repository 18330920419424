import React from 'react';
import { graphql, PageProps } from 'gatsby';
import Layout from '../../components/layout';
import SEO from '../../components/shared/seo';
import { Header } from '../../components/shared/header/header';
import { ArticleContent } from '../../components/article/article-content';
import { Footer } from '../../components/shared/footer/footer';
import { Container } from '../../components/shared/container';
import { RichTextBlock } from 'prismic-reactjs';

export interface PrivacyPolicyData {
  prismicPrivacyPolicyPage: {
    data: {
      big_title: {
        text: string;
      };
      content: {
        raw: RichTextBlock[];
      };
      small_title: {
        text: string;
      };
    };
  };
}

const PrivacyPolicyPage: React.FC<PageProps<PrivacyPolicyData>> = ({
  data,
}) => {
  const { data: privacyPolicytData } = data?.prismicPrivacyPolicyPage;
  return (
    <Layout>
      <SEO title="Privacy Policy" />
      <Header />
      <div className="pb-36 sm:pb-0 mt-36 sm:mt-28">
        <h1>
          <Container paddingRight="big">
            <span className="header-xs text-greyscale-mediumlight -mb-3 sm:-mb-4">
              {privacyPolicytData.small_title.text}
            </span>
          </Container>
          <div className="max-w-body m-auto ">
            <span className="header-2xl whitespace-nowrap text-ecit-blue mt-0 mb-2 sm:mt-10 sm:mb-13.5">
              {privacyPolicytData.big_title.text}
            </span>
          </div>
        </h1>
        <Container paddingRight="big">
          <ArticleContent
            content={[
              {
                type: 'content',
                content: privacyPolicytData.content.raw,
              },
            ]}
          />
        </Container>
      </div>
      <Footer />
    </Layout>
  );
};

export const query = graphql`
  query ($id: String!) {
    prismicPrivacyPolicyPage(lang: { eq: $id }) {
      data {
        big_title {
          text
        }
        content {
          raw
        }
        small_title {
          text
        }
      }
    }
  }
`;

export default PrivacyPolicyPage;
